import { EVENT_DEFAULT } from "./config";
import { mapSpec } from "./map";
import { appendData } from "./append";
import { AnalyticsEventDetail } from "./types";
import { sleep } from "utils/sleep";
import { memoryStorage } from "./storage";
import { useEnv } from "hooks/useEnv";
import { processEnvServer } from "hooks/useSsrHooks";
import yn from "yn";

const env = useEnv();

export const emitAnalyticsEvent = async (
  DOMNode: HTMLElement,
  eventArrayType: string | Array<string>,
  local: Object = {}
): Promise<CustomEvent[]> => {
  const isConcierge = (env?.APP_ID || "").includes("CONCIERGE");
  if (isConcierge) {
    return Promise.resolve([]);
  }
  const isEventArray = Array.isArray(eventArrayType)
    ? eventArrayType
    : [eventArrayType];
  const customEvents = await Promise.all(
    isEventArray.map(async eventType => {
      const detail: AnalyticsEventDetail = appendData(
        mapSpec({
          type: eventType,
          spec: { fields: [] },
          data: {
            local
          },
          payload: {}
        })
      );
      const ce = new CustomEvent(`${eventType}`, {
        ...EVENT_DEFAULT,
        detail
      });
      if (DOMNode) {
        if (!processEnvServer) {
          const previousPageURLs = memoryStorage.getItem("previousPageURL");
          const currentURL = window.location.href;
          let removeDataLayer = false;
          if (
            previousPageURLs &&
            ce?.detail?.type !== "ga4-tracking-int-universal-click" &&
            previousPageURLs !== currentURL
          ) {
            await sleep(500);
          }

          const prevURL = memoryStorage.getItem("previousPageURL");
          const dl = window?.["dataLayer"];
          if (
            (detail.data.prevHistory?.length > 1 || prevURL) &&
            window?.["dataLayer"] &&
            currentURL !== prevURL
          ) {
            removeDataLayer = true;
            memoryStorage.setItem("previousPageURL", currentURL);
          } else if (!prevURL) {
            memoryStorage.setItem("previousPageURL", currentURL);
          }
          if (
            ce?.detail?.type === "ga4-tracking-int-remove_from_cart" ||
            ce?.detail?.type === "ga4-tracking-int-add-to-cart"
          ) {
            removeDataLayer = true;
          }
          if (yn(env.ANALYTICS_CLEANUP_DATALAYER) && removeDataLayer && dl) {
            dl?.push({ ecommerce: null });
            // Remove rest of the events and keep GTM script and GTM push function
            for (let i = dl?.length - 1; i >= 0; i--) {
              if (
                dl[i]?.event !== "gtm.js" &&
                dl[i]?.event !== "gtm.dom" &&
                dl[i]?.event !== "gtm.load"
              ) {
                dl?.splice(i, 1);
              }
            }
            dl?.unshift({ ecommerce: null });
            window["dataLayer"] = dl;
          }
        }
        DOMNode.dispatchEvent(ce);
      }
      return ce;
    })
  );

  return customEvents;
};
