import {
  AnalyticsEventDetail,
  ObjectMapper,
  GTMPageField,
  GTMCategoryField,
  GTMUserField,
  GTMProductField,
  GTMChannelField,
  GTMChannelEntryField,
  GTMPdpclickField,
  GTMItemField,
  ValueObject,
  FieldMapper,
  InternalLocation,
  GTMSwatchesField,
  GTMShopbyroomField,
  GTMTradeRequestField,
  GTMZeroResultsField,
  GTMOnlineReturnItems,
  IReturnItem,
  GTMOnlineRemoveItem,
  RHToken,
  GTMPaymentLinkVisitedTimeField,
  GTMSearchField
} from "./types";
import * as resources from "./resources.json";
import {
  compare,
  isGDPRCompliantORCookieAccepted,
  getPriceData,
  preConfigureProduct
} from "./utils";
import he from "he";
import { memoryStorage } from "./storage";
import { getCookie } from "utils/cookies";
import { processEnvServer } from "hooks/useSsrHooks";
import yn from "yn";
import { useEnv } from "hooks/useEnv";
import { setStorageValue } from "hooks/useCookiesWithPermission";
import moment from "moment";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { calculateSubtotalAmount } from "utils/cartHelpers";
import {
  countryFullNameMapper,
  countryCurrencyMapper
} from "resources/countries-config.json";
import startCase from "lodash.startcase";

const isRHDomain = (url: string = ""): string | null =>
  Object.keys(resources.domains).find(d => url.includes(d)) || null;

const createTitle = (headerTitle?: string, headerSubtitle?: string): string =>
  headerTitle || headerSubtitle
    ? he.decode(
        `${headerTitle || ""}${headerSubtitle ? " " + headerSubtitle : ""}`
      )
    : document.title;

const mapBrandCode = (domain: string): string =>
  (resources.domains as any)[domain].brand;

const getBrand = (location: InternalLocation) => {
  let domain = location?.hostname;
  const hostname = domain?.split(".");
  const ext = hostname?.slice(-2)[0];
  if (domain?.includes("rhguesthouse")) {
    domain = "rhguesthouse.com";
  } else if (ext !== "rh") {
    const hasTwoSubDomains = hostname.length === 4;
    domain = (
      hasTwoSubDomains
        ? hostname
            .reduce(
              (prev, curr, idx) => (idx === 1 ? prev : `${prev}.${curr}`),
              ""
            )
            .slice(1)
        : hostname.slice(1).join(".")
    ).replace("rhnonprod", "rh");
  }
  return (resources.brands as any)[mapBrandCode(domain)];
};

const getNavigationDetails = () => {
  let catalogArray: Array<any> = [];
  if (!processEnvServer && localStorage.getItem("analytics-catalog")) {
    catalogArray = JSON.parse(localStorage.getItem("analytics-catalog") || "");
  }
  return catalogArray;
};

export const mapField = (
  input: ValueObject,
  mappers: Array<FieldMapper>,
  defaultOutput: any = undefined
): any => {
  const resultOfMatch = mappers.find((mapper: FieldMapper) =>
    compare(input, mapper.assert)
  );
  return resultOfMatch ? resultOfMatch.output : defaultOutput;
};

export const fieldBuilder: ObjectMapper = {
  page: (detail: AnalyticsEventDetail): GTMPageField => {
    const prevLocation =
      detail.data.local?.previousHistory &&
      detail.data.prevHistory?.[detail.data.prevHistory?.length - 1];
    const location = prevLocation || detail.data.location!;
    const brand = getBrand(location as InternalLocation);
    const pathname =
      location?.hostname?.includes("rhguesthouse") && location?.pathname === "/"
        ? "/guesthouse"
        : location?.hostname?.includes("rhguesthouse") &&
          location?.pathname === "/rooms"
        ? "/guesthouse/rooms"
        : resources.intlPaths.includes(location.pathname)
        ? location.pathname.concat("/")
        : location.pathname.replace(".jsp", "");
    const regex = /\d/g;

    //checking if current page is a INTL site
    const env = useEnv();
    const FEATURE_INTERNATIONAL = yn(env.FEATURE_INTERNATIONAL);

    const locationPath = FEATURE_INTERNATIONAL
      ? pathname.replace(regex, "").replace(/\/..\/(en|fr|de|nl|es)/gi, "")
      : pathname.replace(regex, "");

    const matchDynamicPath = Object.keys(resources.paths).reduce<{ data: any }>(
      (res, key) => {
        if (
          locationPath?.startsWith(key) &&
          key?.length > (res?.data?.key || "")?.length
        ) {
          return resources.paths[key];
        }
        return res;
      },
      { data: null }
    );

    const pathData = (resources.paths as any)[locationPath] || matchDynamicPath;
    let brandName = "";
    const catalog = detail.data.location?.paramMap?.catalog;
    const previousUrl =
      !processEnvServer && localStorage.getItem("analytics-previous-url");
    if (!brandName) {
      const brandLogoName = document
        .getElementById("nav-logo-img")
        ?.querySelector("img")?.alt;
      if (brandLogoName?.includes("BH") && catalog === "BH") {
        brandName = "BeachHouse";
      } else if (brandLogoName?.includes("SH") && catalog === "SH") {
        brandName = "SkiHouse";
      } else if (brandLogoName?.includes("OD") && catalog === "OD") {
        brandName = "Outdoor";
      } else if (brandLogoName?.includes("MO")) {
        brandName = "Modern";
      } else if (brandLogoName?.includes("BC")) {
        brandName = "B&C";
      } else if (brandLogoName?.includes("TN")) {
        brandName = "Teen";
      } else if (brandLogoName?.includes("IN")) {
        brandName = "Interiors";
      }
    }
    let previous = detail.data.history!.previous?.slice(-2)[0]?.href || null;
    const previousURL = previousUrl && JSON.parse(previousUrl);
    const fromNavigation = memoryStorage.getItem("fromNav") ?? false;
    if (previous === location.href) {
      previous =
        document.referrer && [null, {}].includes(detail.data.history?.state)
          ? document.referrer
          : detail.data.history!.previous?.length <= 1
          ? (location.href !== previousURL?.prevURL &&
            location.href !== previousURL?.currentURL
              ? previousURL?.currentURL
              : location.href !== previousURL?.prevURL
              ? previousURL?.prevURL
              : previousURL?.currentURL) || previous
          : previous;
    }
    const urlObj = {
      prevURL: previous || location.href,
      currentURL: location.href,
      fromNavigation
    };
    if (!processEnvServer) {
      setStorageValue({
        storageKey: "analytics-previous-url",
        value: JSON.stringify(urlObj)
      });
    }
    const title =
      pathData?.title ||
      detail?.data?.local?.displayName ||
      detail?.data?.location?.paramMap?.context ||
      pathData?.name ||
      document?.title;
    const sessionData = memoryStorage.getItem("analytics-sessionDetails") || {};
    const titleBrand =
      brandName !== ""
        ? `RH ${brandName}`
        : brand.brand === "Core"
        ? "RH"
        : `RH ${brand.brand}`;
    memoryStorage.setItem("ga4_pagedata", {
      path: locationPath,
      title: `${title} | ${titleBrand}`,
      type:
        (pathData?.ga4_type ? pathData?.ga4_type : pathData?.type) ||
        pathData?.section,
      login_status: sessionData?.authenticated ? "Success" : "Failure",
      error: locationPath?.includes("error") ? pathData?.key : "",
      membership_id: sessionData?.membershipInfo?.membershipId || "",
      location: (detail.data?.local?.url
        ? detail.data.location?.protocol +
          "//" +
          detail.data.location?.host +
          detail.data.local?.url
        : location.href)!,
      referrer: previous || location.href,
      pathData,
      brand: brandName !== "" ? brandName : brand.brand
    });
    return {
      key: pathData?.key,
      section: detail.data.local?.isEmailMarketingFooter
        ? "Footer"
        : detail.data.local?.isEmailMarketingPopup
        ? "Pop up"
        : pathData?.section,
      type: pathData?.type,
      name: pathData?.name || null,
      subname: pathData?.subname || null,
      domain: {
        domain: brand.domain,
        brand: brandName !== "" ? brandName : brand.brand,
        affiliate: brand.affiliate,
        logoSelector: brand.logoSelector,
        origin: location.origin
      },
      url: (detail.data?.local?.url
        ? detail.data.location?.protocol +
          "//" +
          detail.data.location?.host +
          detail.data.local?.url
        : location.href)!,
      referrer: previous || location.href,
      test: false,
      title: `${title} | ${titleBrand}`
    };
  },
  paymentlinkvisitedtime: (
    detail: AnalyticsEventDetail
  ): GTMPaymentLinkVisitedTimeField | undefined => {
    const page = fieldBuilder.page(detail);
    if (
      page.type === "payment" ||
      page.type === "payment-thankyou" ||
      page.type === "payment-expiredorder" ||
      page.type === "purchase" ||
      page.type === "checkout"
    ) {
      const orderExpiryDate =
        detail.data.local?.orderExpiryDate ||
        memoryStorage.getItem("OrderExpiryDate");
      const orderStatus =
        detail.data.local?.orderStatus || memoryStorage.getItem("OrderStatus");
      if (orderExpiryDate) {
        const expiryDate = new Date(orderExpiryDate);
        const currentDate = new Date();
        const timeSpan =
          (expiryDate.valueOf() - currentDate.valueOf()) /
          (1000 * 60 * 60 * 24);
        const catagory = orderStatus
          ? "Order Placed"
          : timeSpan < 0
          ? "Reached after 72 hours"
          : timeSpan > 2
          ? "Reached within 24 hours"
          : timeSpan > 1
          ? "reached in 24 to 48 hours"
          : "Reached in 48 to 72 hopurs";
        return { expirytime: orderExpiryDate, type: catagory };
      }
    }
    return;
  },
  galleryname: (detail: AnalyticsEventDetail): string | undefined => {
    const page = fieldBuilder.page(detail);
    if (
      page.type === "payment" ||
      page.type === "payment-thankyou" ||
      page.type === "payment-expiredorder" ||
      page.type === "purchase" ||
      page.type === "checkout"
    ) {
      const galleryname =
        detail?.data?.local?.galleryName ||
        detail?.data?.local?.transactionData?.galleryName ||
        memoryStorage.getItem("analytics-galleryname");
      if (galleryname) {
        return galleryname;
      }
    }
    return;
  },
  category: (detail: AnalyticsEventDetail): GTMCategoryField | undefined => {
    const page = fieldBuilder.page(detail);
    const url =
      page.referrer === page.url && document.referrer
        ? document.referrer
        : page.referrer;
    let rhdomain = isRHDomain(url);
    rhdomain =
      url?.includes("rhnonprod") || url?.includes("localhost")
        ? "true"
        : rhdomain;
    const backClick = detail.data.local?.action === "POP" ? true : false;
    let type = page.section.toLowerCase();
    let subtype = page.key;
    const catalogFromLocal =
      !processEnvServer &&
      (memoryStorage.getItem("analytics-catalog") ||
        localStorage.getItem("analytics-catalog"));
    const category =
      !processEnvServer && localStorage.getItem("analytics-category");
    const isNavigatingFromSearch =
      !processEnvServer &&
      (getCookie("navigating_from_search") ||
        localStorage.getItem("analytics-navigatingFromSearch"));
    const catagoryObj =
      !processEnvServer && localStorage.getItem("analytics-categoryForProduct");
    const catId = !processEnvServer && localStorage.getItem("analytics-catId");
    let fromNav = detail.data.location?.paramMap.fromNav;
    let catalog = {} as any;
    const headerTitle = document
      .getElementsByClassName("MuiContainer-root")[0]
      ?.querySelector("h1")?.innerText;
    const headerSubTitle = document
      .getElementsByClassName("MuiContainer-root")[0]
      ?.querySelector("h3")?.innerText;
    const { closestAnchor } = detail.data.local;
    const navPath =
      !processEnvServer && localStorage.getItem("analytics-navCatalog");
    const categoryTitleName =
      !processEnvServer && localStorage.getItem("analytics-category-title");
    const fromNavigation =
      !processEnvServer && Boolean(localStorage.getItem("fromNav"));
    const isExternal =
      !processEnvServer && Boolean(localStorage.getItem("isExternal"));
    // For Banner Clicks
    if (
      !processEnvServer &&
      detail.type === "ga4-tracking-int-universal-click"
    ) {
      if (closestAnchor?.getAttribute("bannertype")) {
        setStorageValue({
          storageKey: "analytics-navigationFromBanner",
          value: "true"
        });
      } else {
        localStorage.removeItem("analytics-navigationFromBanner");
      }
    }
    const isBannerClick =
      !processEnvServer &&
      localStorage.getItem("analytics-navigationFromBanner")
        ? true
        : false;
    if (
      detail.type === "ga4-tracking-int-universal-click" &&
      detail.data.local?.target?.innerText === "SALE"
    ) {
      return {
        type: "catalog",
        subtype: "collections",
        catalog: catId ? JSON.parse(catId) : ""
      };
    } else if (page.url.includes("collections.jsp") || fromNav) {
      if (!processEnvServer) {
        localStorage.removeItem("analytics-categoryForProduct");
      }
      if (isBannerClick) {
        type = "Banner";
        subtype = "collections";
        catalog = {
          cat: {
            id: detail.data.location?.paramMap?.categoryId,
            title: createTitle(headerTitle, headerSubTitle)
          }
        };
      } else if (
        page.referrer.includes("products.jsp") &&
        isNavigatingFromSearch &&
        JSON.parse(isNavigatingFromSearch)
      ) {
        if (!processEnvServer) {
          localStorage.removeItem("analytics-navigatingFromSearch");
        }
        if (category) {
          const tempCategoryObj = JSON.parse(category);
          catalog = tempCategoryObj.catalog;
        }
      } else if (page.referrer.includes("category.jsp") && !catalogFromLocal) {
        catalog = {
          cat: {
            id: detail.data.location?.paramMap?.categoryId,
            title: createTitle(headerTitle, headerSubTitle)
          }
        };
      } else if (!rhdomain) {
        type = "External";
        catalog = {
          cat: {
            id: detail.data.location?.paramMap?.categoryId,
            title: createTitle(headerTitle, headerSubTitle)
          }
        };
      } else if (!fromNavigation && document.referrer === "") {
        if (
          detail.data.location?.paramMap?.cm_mmc &&
          detail.data.location?.paramMap?.cm_mmc === "ecatalog"
        ) {
          type = "Ecatalog";
        } else {
          type = "External";
        }
        catalog = {
          cat: {
            id: detail.data.location?.paramMap?.categoryId,
            title:
              detail.data.location?.paramMap?.Ntt ||
              categoryTitleName ||
              createTitle(headerTitle, headerSubTitle)
          }
        };
      } else if (
        ((detail.data.location?.paramMap?.parentCatId &&
          page.referrer.includes("results")) ||
          (isNavigatingFromSearch && JSON.parse(isNavigatingFromSearch))) &&
        !fromNav
      ) {
        const searchObj =
          !processEnvServer && localStorage.getItem("analytics-search");
        if (searchObj) {
          type = "search";
          catalog = {
            cat: {
              id: detail.data.location?.paramMap?.categoryId,
              title: createTitle(headerTitle, headerSubTitle)
            }
          };
        }
      } else if (
        page.referrer !== page.url &&
        [null, {}].includes(detail.data.history?.state)
      ) {
        if (category) {
          const tempCategoryObj = JSON.parse(category);
          let tempCatalog = tempCategoryObj.catalog;
          let id = "";
          let title = "";
          if (catId) {
            const tempCatId = JSON.parse(catId);
            id = tempCatId.cat.id.concat(
              `-${detail.data.location?.paramMap?.categoryId}`
            );
            title = tempCatId.cat.title.concat(
              `-${detail.data.local?.displayName}`
            );
          }
          const cat = {
            id,
            title:
              title !== "" ? title : createTitle(headerTitle, headerSubTitle)
          };
          tempCatalog.cat = cat;
          catalog = tempCatalog;
        }
        if (!processEnvServer) localStorage.removeItem("fromNav");
      } else if (catalogFromLocal) {
        type = "catalog";
        const tempCatObj =
          typeof catalogFromLocal === "string"
            ? JSON.parse(catalogFromLocal)
            : catalogFromLocal;
        if (category) {
          const tempCategoryObj = JSON.parse(category);
          if (tempCategoryObj.catalog !== tempCatObj) {
            if (detail.type === "ga4-tracking-int-universal-click") {
              catalog = JSON.parse(catId || "");
            } else {
              if (tempCatObj[0] && tempCatObj[0].id) {
                catalog["topCat"] = {
                  id: tempCatObj[0].id,
                  title: he.decode(tempCatObj[0].name)
                };
              }
              if (tempCatObj[1] && tempCatObj[1].id) {
                catalog["parentCat"] = {
                  id: tempCatObj[1].id,
                  title: he.decode(tempCatObj[1].name)
                };
              }
              if (tempCatObj[2] && tempCatObj[2].id) {
                catalog["cat"] = {
                  id: tempCatObj[2].id,
                  title: he.decode(tempCatObj[2].name)
                };
              }
            }
          } else {
            catalog = tempCategoryObj.catalog;
          }
        } else {
          if (detail.type === "ga4-tracking-int-universal-click") {
            catalog = JSON.parse(catId || "");
          } else {
            if (tempCatObj[0] && tempCatObj[0].id) {
              catalog["topCat"] = {
                id: tempCatObj[0].id,
                title: he.decode(tempCatObj[0].name)
              };
            }
            if (tempCatObj[1] && tempCatObj[1].id) {
              catalog["parentCat"] = {
                id: tempCatObj[1].id,
                title: he.decode(tempCatObj[1].name)
              };
            }
            if (tempCatObj[2] && tempCatObj[2].id) {
              catalog["cat"] = {
                id: tempCatObj[2].id,
                title: he.decode(tempCatObj[2].name)
              };
            }
          }
        }
      } else if (page.referrer.includes("products.jsp") && backClick) {
        if (category) {
          const tempCategoryObj = JSON.parse(category);
          catalog = tempCategoryObj.catalog;
        }
      }
      if (
        !processEnvServer &&
        detail.type !== "ga4-tracking-int-universal-click"
      ) {
        memoryStorage.removeItem("analytics-catalog");
        localStorage.removeItem("analytics-catalog");
      }

      if (page.type !== "product") {
        if (catalog && Object.keys(catalog).length === 0 && navPath) {
          catalog = JSON.parse(navPath);
        }
        const category = {
          type,
          subtype: page.key,
          catalog
        };
        if (
          !processEnvServer &&
          page.referrer !== page.url &&
          detail.data.history?.state
        ) {
          setStorageValue({
            storageKey: "analytics-catId",
            value: JSON.stringify(category.catalog)
          });
        }
        if (!processEnvServer) {
          setStorageValue({
            storageKey: "analytics-category",
            value: JSON.stringify(category)
          });
          if (type?.includes("External")) {
            setStorageValue({ storageKey: "isExternal", value: "true" });
          }
        }
        return {
          type,
          subtype,
          catalog
        };
      }
    } else if (
      page.url.includes("products") ||
      page.url.includes("sale-products")
    ) {
      let access =
        page.referrer.includes("collections") && !catalogFromLocal
          ? "child"
          : "direct";
      if (isBannerClick) {
        type = "Banner";
        subtype = "products";
        access = page.referrer.includes("collections") ? "child" : "direct";
        if (page.referrer.includes("collections.jsp")) {
          catalog["subCat"] = {
            id: detail.data.location?.paramMap?.categoryId,
            title: createTitle(headerTitle, headerSubTitle)
          };
        } else {
          catalog = {
            cat: {
              id: detail.data.location?.paramMap?.categoryId,
              title: createTitle(headerTitle, headerSubTitle)
            }
          };
        }
      } else if (page.referrer.includes("product.jsp") && backClick) {
        if (!processEnvServer) {
          localStorage.removeItem("analytics-navigatingFromSearch");
        }
        if (catagoryObj) {
          const tempCategoryObj = JSON.parse(catagoryObj);
          catalog = tempCategoryObj.catalog;
        }
      } else if (!rhdomain) {
        type = "External";
        catalog = {
          cat: {
            id: detail.data.location?.paramMap?.categoryId,
            title: createTitle(headerTitle, headerSubTitle)
          }
        };
      } else if (
        (page.referrer.includes("results") ||
          (isNavigatingFromSearch && JSON.parse(isNavigatingFromSearch))) &&
        !page.referrer.includes("collections") &&
        page.referrer !== page.url
      ) {
        const searchObj =
          !processEnvServer && localStorage.getItem("analytics-search");
        if (searchObj) {
          type = "search";
          catalog = {
            cat: {
              id: detail.data.location?.paramMap?.categoryId,
              title: createTitle(headerTitle, headerSubTitle)
            }
          };
        }
      } else if (isNavigatingFromSearch && JSON.parse(isNavigatingFromSearch)) {
        type = "search";
        if (page.url === page.referrer) {
          access = "child";
          if (catagoryObj) {
            const tempCategoryObj = JSON.parse(catagoryObj);
            catalog = {
              cat: {
                id: tempCategoryObj.catalog.cat.id,
                title: he.decode(tempCategoryObj.catalog.cat.title)
              },
              subCat: {
                id: detail.data.location?.paramMap?.categoryId,
                title: createTitle(headerTitle, headerSubTitle)
              }
            };
          }
        } else if (category) {
          const tempCategoryObj = JSON.parse(category);
          catalog = {
            cat: {
              id: tempCategoryObj.catalog.cat.id,
              title: he.decode(tempCategoryObj.catalog.cat.title)
            },
            subCat: {
              id: detail.data.location?.paramMap?.categoryId,
              title: createTitle(headerTitle, headerSubTitle)
            }
          };
        }
      } else if (catalogFromLocal) {
        const tempCatObj =
          typeof catalogFromLocal === "string"
            ? JSON.parse(catalogFromLocal)
            : catalogFromLocal;
        if (detail.type === "ga4-tracking-int-universal-click") {
          catalog = JSON.parse(catId || "");
        } else {
          if (tempCatObj[0] && tempCatObj[0].id) {
            catalog["topCat"] = {
              id: tempCatObj[0].id,
              title: he.decode(tempCatObj[0].name)
            };
          }
          if (tempCatObj[1] && tempCatObj[1].id) {
            catalog["parentCat"] = {
              id: tempCatObj[1].id,
              title: he.decode(tempCatObj[1].name)
            };
          }
          if (tempCatObj[2] && tempCatObj[2].id) {
            catalog["cat"] = {
              id: tempCatObj[2].id,
              title: he.decode(tempCatObj[2].name)
            };
          }
        }
      } else if (page.referrer.includes("collections")) {
        if (isNavigatingFromSearch && JSON.parse(isNavigatingFromSearch)) {
          type = "search";
        }
        if (isExternal) {
          type = "External";
          localStorage.removeItem("isExternal");
        }
        if (category) {
          const tempCategoryObj = JSON.parse(category);
          tempCategoryObj.catalog["subCat"] = {
            id: detail.data.location?.paramMap?.categoryId,
            title: categoryTitleName || createTitle(headerTitle, headerSubTitle)
          };
          catalog = tempCategoryObj.catalog;
        }
      } else if (!fromNavigation && document.referrer === "") {
        if (
          detail.data.location?.paramMap?.cm_mmc &&
          detail.data.location?.paramMap?.cm_mmc === "ecatalog"
        ) {
          type = "Ecatalog";
        } else {
          type = "External";
        }
        catalog = {
          cat: {
            id: detail.data.location?.paramMap?.categoryId,
            title: categoryTitleName || createTitle(headerTitle, headerSubTitle)
          }
        };
      } else if (
        page.referrer !== page.url &&
        [null, {}].includes(detail.data.history?.state)
      ) {
        access = "child";
        if (catagoryObj) {
          const tempCategoryObj = JSON.parse(catagoryObj);
          tempCategoryObj.catalog["subCat"] = {
            id: detail.data.location?.paramMap?.categoryId,
            title: createTitle(headerTitle, headerSubTitle)
          };
          catalog = tempCategoryObj.catalog;
        }
      }
      if (!processEnvServer) localStorage.removeItem("fromNav");
      if (catalog && Object.keys(catalog).length === 0 && navPath) {
        catalog = JSON.parse(navPath);
      }
      const categoryForProduct = {
        type,
        subtype: page.key,
        access,
        catalog
      };
      if (!processEnvServer) {
        setStorageValue({
          storageKey: "analytics-categoryForProduct",
          value: JSON.stringify(categoryForProduct)
        });
      }
      if (
        !processEnvServer &&
        detail.type !== "ga4-tracking-int-universal-click"
      ) {
        memoryStorage.removeItem("analytics-catalog");
        localStorage.removeItem("analytics-catalog");
      }
      return {
        type,
        subtype,
        catalog,
        access
      };
    } else if (page.url.includes("catalog/sale/index.jsp")) {
      if (detail.type === "ga4-tracking-int-universal-click") {
        return {
          type: "catalog",
          subtype: "collections",
          catalog: catId ? JSON.parse(catId) : ""
        };
      }
      return {
        type: "sale",
        subtype: "home"
      };
    } else if (page.url.includes("catalog/sale/final-sale.jsp")) {
      if (detail.type === "ga4-tracking-int-universal-click") {
        return {
          type: "catalog",
          subtype: "collections",
          catalog: catId ? JSON.parse(catId) : ""
        };
      }
      return {
        type: "sale",
        subtype: "finalsale",
        catalog:
          catalogFromLocal && catalogFromLocal?.length == 3
            ? {
                topCat: catalogFromLocal[0],
                parentCat: catalogFromLocal[1],
                cat: catalogFromLocal[2]
              }
            : {
                subCat: catalogFromLocal[0]
              }
      };
    }
  },
  search: (detail: AnalyticsEventDetail): GTMSearchField | undefined => {
    const backClick = ["POP"].includes(
      detail.data.history!.previous.slice(-1)[0].action
    );
    const action = detail.data.local?.search && backClick;
    const page = fieldBuilder.page(detail);
    const isNavigatingFromSearch =
      (!processEnvServer && getCookie("navigating_from_search")) ||
      localStorage.getItem("analytics-navigatingFromSearch");
    let fromNav = detail.data.location?.paramMap.fromNav;
    if (!processEnvServer && fromNav) {
      localStorage.removeItem("analytics-navigatingFromSearch");
      setStorageValue({ storageKey: "analytics-access", value: "navigation" });
    }
    const access =
      !processEnvServer &&
      (getCookie("search_access") || localStorage.getItem("analytics-access"));
    if (
      ((page.referrer.includes("results") || page.url.includes("results")) &&
        !fromNav &&
        !action) ||
      (isNavigatingFromSearch &&
        JSON.parse(isNavigatingFromSearch) &&
        !fromNav &&
        !page.referrer.includes("rooms") &&
        page.key !== "product" &&
        !action) ||
      (page.key === "product" &&
        (detail.data.location?.paramMap.category === "search" ||
          detail.data.location?.paramMap.categoryId === "search")) ||
      (detail.data.local?.search && !action)
    ) {
      let term = detail.data.location?.paramMap.Ntt;
      let term2 = detail.data.location?.paramMap.Ntt2;
      let filter = detail.data.location?.paramMap.N;

      let sort = detail.data.location?.paramMap?.Ns;
      if (
        page.key === "collections" ||
        page.key === "products" ||
        page.key === "product" ||
        detail.data.location?.paramMap.categoryId === "search"
      ) {
        const searchDetail =
          !processEnvServer && localStorage.getItem("analytics-search");
        if (searchDetail && !term && !term2) {
          const tempSearchObj = JSON.parse(searchDetail);
          term = tempSearchObj.term;
          sort = tempSearchObj.sort;
          term2 = tempSearchObj.term2;
          filter = tempSearchObj.filter;
        }
      }
      const search = {
        term,
        sort,
        term2,
        filter
      };
      if (!processEnvServer) {
        setStorageValue({
          storageKey: "analytics-search",
          value: JSON.stringify(search)
        });
      }
      const fusionData =
        !processEnvServer &&
        (detail?.data?.local?.fusionData ||
          localStorage.getItem("fusion_data"));
      let filterQueries = [];
      let fusion_query_id = "";
      if (fusionData) {
        const tempFusionData = JSON.parse(fusionData);
        filterQueries = tempFusionData.fusion_filter;
        fusion_query_id = tempFusionData.fusion_id;
      }
      if (
        detail.type === "ga4-tracking-int-universal-click" &&
        page.key !== "search"
      ) {
        return;
      }
      return {
        term,
        type: "Results",
        sort,
        access: fromNav ? "navigation" : access || "direct",
        isNewSearch:
          decodeURIComponent(
            document.cookie.replace(
              new RegExp(
                "(?:(?:^|.*;)\\s*" +
                  encodeURIComponent("fusion_search").replace(
                    /* eslint-disable no-useless-escape */
                    /[\-\.\+\*]/g,
                    "\\$&"
                  ) +
                  "\\s*\\=\\s*([^;]*).*$)|^.*$"
              ),
              "$1"
            )
          ) === "true"
            ? true
            : false,
        fusion_query_id,
        filterQueries,
        onBackClick: backClick,
        term2,
        filter
      };
    }
  },
  searches: (detail: AnalyticsEventDetail): number | undefined => {
    const page = fieldBuilder.page(detail);
    if (
      detail.type === "ga4-tracking-int-universal-click" &&
      page.key !== "search"
    ) {
      return;
    }
    return (
      detail.data.history?.previous.filter(p => p.href.includes("/search/"))
        .length || 0
    );
  },
  user: async (detail: AnalyticsEventDetail): Promise<GTMUserField | null> => {
    const sessionData = memoryStorage.getItem("analytics-sessionDetails") || {};
    const rhUser = sessionData?.rhUser;
    /** This probably might be broken when membershipInfo is removed from session */
    const membershipStatus =
      (sessionData?.membershipInfo?.userHasActiveMembership ||
        sessionData?.membershipInfo?.membershipSkuIsOnCart) ??
      false;
    const member = rhUser?.userType;
    const mapObject =
      rhUser?.userType === "TRADE" || rhUser?.userType === "CONTRACT"
        ? { member }
        : { membershipStatus, member };
    return (
      (isGDPRCompliantORCookieAccepted() &&
        (detail.type.includes("ga4")
          ? {
              type: mapField(mapObject, resources.mappers["user.type"]),
              id: rhUser?.id
            }
          : {
              type: mapField(mapObject, resources.mappers["user.type"]),
              id: rhUser?.id,
              is_member: membershipStatus,
              userType: rhUser?.userType
            })) ||
      null
    );
  },
  product: async (
    detail: AnalyticsEventDetail
  ): Promise<GTMProductField | undefined> => {
    const location = detail.data.location;
    const urlProductId = location?.pathname?.match(/prod(\d+)/);
    const productId =
      location?.paramMap?.productId || (urlProductId && urlProductId[1]);
    if (productId) {
      const productData =
        !processEnvServer && localStorage.getItem("analytics-product-data");
      const product = productData && JSON.parse(productData);
      let productType = product.emptyProduct
        ? "Shell"
        : location?.paramMap?.src
        ? location?.paramMap?.src === "rel"
          ? "Related"
          : "Upsell"
        : "Standard";

      if (detail.data.local.item) {
        const { id, pricing, inStockProductCard } = detail.data.local.item;
        productType =
          id !== productId
            ? "ShellRelated"
            : inStockProductCard
            ? pricing?.onSale
              ? "OnSale"
              : "InStock"
            : "Related";
      }

      return {
        name: product.displayName,
        id: productId,
        brand: getBrand(location as InternalLocation).brand,
        sale: product.onSale,
        productType,
        monogrammable: detail.data.local?.monogrammable || false,
        personalizable: detail.data.local?.personalizable || false
      };
    }
    return undefined;
  },
  channel: async (
    detail: AnalyticsEventDetail
  ): Promise<GTMChannelField | undefined> => {
    const prevPayload = !processEnvServer && localStorage.getItem("payload");
    let prevPageObj;
    if (prevPayload) {
      const tempObj = JSON.parse(prevPayload);
      prevPageObj = tempObj;
    }
    const backClick = ["POP"].includes(
      detail.data.history!.previous?.slice(-1)?.[0]?.action
    );
    const catagoryObj =
      !processEnvServer && localStorage.getItem("analytics-categoryForProduct");
    const catagory =
      !processEnvServer && localStorage.getItem("analytics-category");
    const searchObj =
      !processEnvServer && localStorage.getItem("analytics-search");
    const isNavigatingFromSearch =
      !processEnvServer &&
      localStorage.getItem("analytics-navigatingFromSearch");
    const categoryProdObj =
      !processEnvServer && localStorage.getItem("analytics-category");
    const productCategory = detail.data.location?.paramMap?.category;
    const page = fieldBuilder.page(detail);
    let search;
    let category;
    if (
      catagory &&
      prevPageObj &&
      prevPageObj.page?.url.includes("fromNav=true")
    ) {
      category = JSON.parse(catagory);
    }
    if (catagoryObj && page.referrer.includes("products")) {
      category = JSON.parse(catagoryObj);
    }
    if (
      catagoryObj &&
      page.referrer.includes("product.jsp") &&
      page.url.includes("src=upsell")
    ) {
      category = JSON.parse(catagoryObj);
    }
    if (
      categoryProdObj &&
      (page.referrer.includes("fromNav=true") ||
        page.referrer.includes("collection.jsp"))
    ) {
      category = JSON.parse(categoryProdObj);
    }
    if (
      (searchObj &&
        isNavigatingFromSearch &&
        JSON.parse(isNavigatingFromSearch) &&
        !page.referrer.includes("rooms")) ||
      productCategory === "search" ||
      page.referrer.includes("fromNav=true")
    ) {
      search = {
        term: "",
        term2: "",
        access: "",
        isNewSearch: false,
        filterQueries: [],
        fusion_query_id: "",
        sessionId: "",
        type: "Results"
      };
      if (searchObj && productCategory !== "search") {
        search = JSON.parse(searchObj);
      } else if (productCategory === "search") {
        search.term = detail.data.location?.paramMap?.Ntt;
        search.term2 = detail.data.location?.paramMap?.Ntt2;
      }
      const access =
        (!processEnvServer && localStorage.getItem("analytics-access")) ===
        "navigation"
          ? "navigation"
          : (!processEnvServer &&
              (getCookie("search_access") ||
                localStorage.getItem("analytics-access"))) ||
            "direct";
      if (search && !search.access && access && page.key === "product") {
        search.access = access;
        search.isNewSearch =
          decodeURIComponent(
            document.cookie.replace(
              new RegExp(
                "(?:(?:^|.*;)\\s*" +
                  encodeURIComponent("fusion_search").replace(
                    /[\-\.\+\*]/g,
                    "\\$&"
                  ) +
                  "\\s*\\=\\s*([^;]*).*$)|^.*$"
              ),
              "$1"
            )
          ) === "true"
            ? true
            : false;
        const fusionData =
          !processEnvServer && localStorage.getItem("fusion_data");
        if (fusionData) {
          const tempFusionData = JSON.parse(fusionData);
          search.filterQueries = tempFusionData.fusion_filter;
          search.fusion_query_id = tempFusionData.fusion_id;
          if (!processEnvServer && detail.type === "tracking-int-add-to-cart") {
            localStorage.removeItem("fusion_data");
          }
        }
        search.onBackClick = backClick;
        search.type = "Results";
      }
    }
    if (page.type === "product") {
      if (
        (detail?.data?.location?.paramMap?.category === "search" &&
          !detail?.data?.location?.paramMap?.productId) ||
        (!category && !search)
      ) {
        return {
          type: "channelentry"
        };
      } else {
        return {
          page: prevPageObj
            ? prevPageObj.channel && prevPageObj.channel.page
              ? prevPageObj.channel.page
              : prevPageObj.page
            : fieldBuilder.page(detail),
          search,
          searches: fieldBuilder.searches(detail),
          type: "pagepath", // pagepath
          category,
          shopbyroom:
            prevPageObj.channel && prevPageObj.channel.shopbyroom
              ? prevPageObj.channel.shopbyroom
              : prevPageObj.shopbyroom
        };
      }
    }
  },
  channelentry: (
    detail: AnalyticsEventDetail
  ): GTMChannelEntryField | undefined => {
    const page = fieldBuilder.page(detail);
    // TODO: Check if the code commented is really necessary

    const prevPayload = !processEnvServer && localStorage.getItem("payload");
    let prevPageObj;
    if (prevPayload) {
      const tempObj = JSON.parse(prevPayload);
      prevPageObj = tempObj;
    }
    const catagoryObj =
      !processEnvServer && localStorage.getItem("analytics-categoryForProduct");
    const catagory =
      !processEnvServer && localStorage.getItem("analytics-category");
    const searchObj =
      !processEnvServer && localStorage.getItem("analytics-search");
    const navPath =
      !processEnvServer && localStorage.getItem("analytics-navCatalog");
    const isNavigatingFromSearch =
      !processEnvServer &&
      localStorage.getItem("analytics-navigatingFromSearch");
    const isBannerClick =
      !processEnvServer &&
      localStorage.getItem("analytics-navigationFromBanner")
        ? true
        : false;
    const productCategory = detail.data.location?.paramMap?.category;
    if (page.type === "product") {
      if (document.title === null) {
        return {
          type: "acquisition",
          subtype: "direct"
        };
      } else if (isBannerClick) {
        // If you landed through banner.
        if (!processEnvServer) {
          localStorage.removeItem("analytics-navigationFromBanner");
        }
        const catalog = {
          cat: {
            id: detail?.data?.location?.paramMap?.productId,
            title: document.title
          }
        };
        return {
          type: "Banner",
          subtype: page.key,
          entry: catalog
        };
      } else if (
        // if you landed through source book on PDP Directly.
        detail?.data?.location?.paramMap?.cm_mmc &&
        detail?.data?.location?.paramMap?.cm_mmc === "ecatalog"
      ) {
        const catalog = {
          cat: {
            id: detail?.data?.location?.paramMap?.productId,
            title: document?.title
          }
        };
        return {
          type: "Ecatalog",
          subtype: page.key,
          entry: catalog
        };
      } else if (
        // SourceBook --> CG Page --> PDP
        catagory &&
        catagory.includes("Ecatalog") &&
        page.referrer.includes("collections.jsp")
      ) {
        const tempObj = JSON.parse(catagory);
        return {
          type: tempObj.type,
          subtype: page.key,
          entry: tempObj.catalog
        };
      } else if (
        // SourceBook --> PG Page --> PDP
        catagoryObj &&
        catagoryObj.includes("Ecatalog") &&
        page.referrer.includes("products.jsp") &&
        !page.url.includes("sale=true")
      ) {
        const tempObj = JSON.parse(catagoryObj);
        return {
          type: tempObj.type,
          subtype: page.key,
          entry: tempObj.catalog
        };
      } else if (
        // External --> CG --> PDP
        catagory &&
        catagory.includes("External") &&
        page.referrer.includes("collections.jsp") &&
        !isNavigatingFromSearch
      ) {
        const tempObj = JSON.parse(catagory);
        return {
          type: tempObj.type,
          subtype: page.key,
          entry: tempObj.catalog
        };
      } else if (
        // External --> PG --> PDP || External --> CG --> PG --> PDP
        catagoryObj &&
        catagoryObj.includes("External") &&
        page.referrer.includes("products.jsp") &&
        !isNavigatingFromSearch
      ) {
        const tempObj = JSON.parse(catagoryObj);
        return {
          type: tempObj.type,
          subtype: page.key,
          entry: tempObj.catalog
        };
      } else if (
        page.referrer.includes("rooms") &&
        productCategory !== "search"
      ) {
        return {
          type: "header",
          subtype: "top",
          entry: "Header/Shop Room"
        };
      } else if (
        catagory &&
        !page.referrer.includes("results") &&
        !isNavigatingFromSearch &&
        productCategory !== "search" &&
        page.referrer.includes("collections.jsp")
      ) {
        let catalogNav = JSON.parse(catagory);
        catalogNav.catalog = navPath ? JSON.parse(navPath) : catalogNav.catalog;
        return {
          type: "header",
          subtype: "category",
          entry: catalogNav.catalog
        };
      } else if (
        catagoryObj &&
        !page.referrer.includes("results") &&
        !isNavigatingFromSearch &&
        productCategory !== "search" &&
        page.referrer.includes("products.jsp") &&
        !page.url.includes("sale=true")
      ) {
        let catalogNav = JSON.parse(catagoryObj);
        catalogNav.catalog = navPath ? JSON.parse(navPath) : catalogNav.catalog;
        return {
          type: "header",
          subtype: "category",
          entry: catalogNav.catalog
        };
      } else if (
        catagoryObj &&
        page.referrer.includes("product.jsp") &&
        page.url.includes("src=upsell")
      ) {
        let catalogNav = JSON.parse(catagoryObj);
        catalogNav.catalog = navPath ? JSON.parse(navPath) : catalogNav.catalog;
        return {
          type: "related",
          subtype: "category",
          entry: catalogNav.catalog
        };
      } else if (
        catagory &&
        prevPageObj &&
        navPath &&
        (catagory.includes("catalog") ||
          prevPageObj.page?.url.includes("fromNav=true"))
      ) {
        let catalogNav = JSON.parse(catagory);
        catalogNav.catalog = navPath ? JSON.parse(navPath) : catalogNav.catalog;
        !processEnvServer && localStorage.removeItem("fromNav");
        return {
          type: "header",
          subtype: "category",
          entry: catalogNav.catalog
        };
      } else if (
        (searchObj &&
          isNavigatingFromSearch &&
          JSON.parse(isNavigatingFromSearch) &&
          !page.referrer.includes("rooms")) ||
        productCategory === "search"
      ) {
        let term = "";
        let term2 = "";
        if (searchObj && productCategory !== "search") {
          const tempSearchObj = JSON.parse(searchObj);
          term = tempSearchObj.term;
          term2 = tempSearchObj.term2;
        } else if (productCategory === "search") {
          term = detail.data.location?.paramMap?.Ntt;
          term2 = detail.data.location?.paramMap?.Ntt2;
        }
        const access =
          !processEnvServer &&
          (getCookie("search_access") ||
            localStorage.getItem("analytics-access"));
        if (access === "direct") {
          return { type: "search", term };
        } else if (access === "typeahead") {
          const clickTerm =
            !processEnvServer &&
            localStorage.getItem("analytics-searchtermclick");
          const type = "typeahead";
          if (productCategory === "search") {
            return {
              type,
              subtype: "product",
              title: term,
              id: detail.data.location?.paramMap?.productId,
              term: term2
            };
          } else {
            let selection = clickTerm;
            selection = selection ? selection.trim() : null;
            selection = selection ? selection.toLowerCase() : null;
            let inIdx = selection ? selection.lastIndexOf(" in ") : null;
            if (inIdx && inIdx > 0 && selection) {
              let keyword = selection.substring(0, inIdx);
              let category = selection.substring(inIdx + 4);
              return {
                type: "typeahead",
                subtype: "category",
                keyword: keyword,
                category: category,
                term: term2
              };
            } else if (selection && inIdx === -1) {
              return {
                type: "typeahead",
                subtype: "keyword",
                keyword: selection,
                term: term2
              };
            } else {
              return {
                type: "error",
                subtype: "typeahead",
                msg: "bad scrape of term"
              };
            }
          }
        }
      } else if (
        catagoryObj &&
        (page?.referrer?.includes("/catalog/sale/index.jsp") ||
          page?.referrer?.includes("/catalog/sale/final-sale.jsp") ||
          page?.referrer?.includes("products.jsp")) &&
        page?.url?.includes("sale=true")
      ) {
        let catalogNav = navPath ? JSON.parse(navPath) : null;
        return {
          type: "sale",
          subtype: "category",
          entry: catalogNav
        };
      }
      // Sale to direct PDP Page Landing.
      else if (
        (page?.referrer?.includes("/catalog/sale/index.jsp") ||
          page?.referrer?.includes("/catalog/sale/final-sale.jsp")) &&
        page?.referrer?.includes("sale=true") &&
        page?.url?.includes("product.jsp") &&
        page?.url?.includes("sale=sale")
      ) {
        return {
          type: "sale",
          subtype: "category",
          entry: navPath ? JSON.parse(navPath) : {}
        };
      }
      // From WishList to PDP
      else if (
        page?.referrer?.includes("wish-list.jsp") &&
        page?.url?.includes("product.jsp")
      ) {
        const productData =
          !processEnvServer && localStorage.getItem("analytics-product-data");
        const productTitle = productData && JSON.parse(productData).displayName;
        const catalog = {
          cat: {
            id: detail?.data?.location?.paramMap?.productId,
            title: productTitle || document.title
          }
        };
        return {
          type: "wishlist",
          subtype: "category",
          entry: catalog
        };
      }
    }
  },
  // operations: (
  //   detail: AnalyticsEventDetail
  // ): GTMOperationsField | undefined => {
  //   const error: ErrorEvent = detail.data.local?.error;
  //   if (error) {
  //     return {
  //       type: error.type,
  //       message: error.message,
  //       supplement: `${error.filename}:${error.lineno}:${error.colno}`
  //     };
  //   }
  // },
  item_list_id: (detail: AnalyticsEventDetail): string =>
    detail.data.local?.item_list_id || null,
  item_list_name_view_item: (detail: AnalyticsEventDetail): string =>
    detail.data.local?.item_list_name_view_item || null,
  items: async (detail: AnalyticsEventDetail): Promise<Array<GTMItemField>> => {
    const emptyProduct =
      !processEnvServer && localStorage.getItem("analytics-emptyProduct");
    const sessionData = memoryStorage.getItem("analytics-sessionDetails") || {};
    const previousUrl =
      !processEnvServer && localStorage.getItem("analytics-previous-url");
    const previousURL = previousUrl && JSON.parse(previousUrl)?.fromNavigation;
    const rhUser = sessionData?.rhUser;
    const panelLineItem =
      (!processEnvServer && memoryStorage.getItem("panelLineItem")) || [];
    let productDocId = "";
    if (emptyProduct) {
      const tempEmptyProduct = JSON.parse(emptyProduct);
      productDocId = tempEmptyProduct
        ? detail.data.location?.paramMap?.productId
        : "";
    }
    const hasMemberPrice: boolean =
      sessionData?.membershipInfo?.userHasActiveMembership; //fixing the membershipDetails AddtoCart items
    const normalPriceKey = hasMemberPrice ? "memberPrice" : "listPrice";
    const priceKey = hasMemberPrice ? "memberPrice" : "itemPrice";
    const page = fieldBuilder.page(detail);
    const urlProductId = detail.data.location?.pathname?.match(/prod(\d+)/);
    const productId =
      detail.data.location?.paramMap?.productId || urlProductId?.[0];
    const productData: Product =
      !processEnvServer &&
      JSON.parse(localStorage.getItem("analytics-product-data") || "{}");
    const fullSkuIdFromParam = detail.data.location?.paramMap?.fullSkuId;
    const relatedProductSku = memoryStorage.getItem("relatedProductSku");
    if (detail.type.includes("ga4")) {
      const isPreconfigureSkuEvents =
        (detail.type.includes("order-placed") ||
          detail.type.includes("view-item") ||
          detail.type.includes("add-to-cart")) &&
        !detail.type.includes("view-item-list");
      if (detail.data.local?.itemList) {
        return detail.data.local?.itemList?.map(item => {
          const sku = item?.item_variant || item?.sku;
          const isPrimaryProduct = productId === item?.item_id;
          const relatedProductSkuId = relatedProductSku?.includes(
            sku?.toString()
          );
          const productConfig =
            item?.productConfig ||
            preConfigureProduct({
              fullSkuId: sku,
              preConfiguredSku: fullSkuIdFromParam,
              relatedProductSkuId
            });
          !processEnvServer && memoryStorage.removeItem("panelLineItem");
          return {
            ...item,
            primaryProduct: isPrimaryProduct,
            ...(isPreconfigureSkuEvents ? { productConfig } : {}),
            ...(item?.atc_method ? { atc_method: item?.atc_method } : {})
          };
        });
      }
      const data =
        detail.data.local?.transactionData ||
        memoryStorage.getItem("analytics-orderDetails");
      const title =
        (!processEnvServer &&
          localStorage.getItem("analytics-category-title")) ||
        "";
      let itemList =
        data?.lineItems ??
        detail.data.local?.itemList ??
        detail.data.local?.item;
      if (!Array.isArray(itemList)) {
        itemList = itemList ? [itemList] : [];
      }
      let price = 0;
      const url = page?.url || "";
      const referrer = page?.referrer || "";
      const prevURLMatch = referrer?.match(/\/([^\/?]+\.jsp)/);
      const pageName = prevURLMatch?.[1] || prevURLMatch?.[2];
      const separatePathStringToFindCurrentPath = referrer?.endsWith("/")
        ? referrer.slice(0, -1).split("/")
        : referrer?.split("/");
      const lastTwoPaths = separatePathStringToFindCurrentPath?.slice(-2);
      const isLanguagePage = lastTwoPaths?.some(path =>
        /(en|fr|de|nl|es)/gi.test(path)
      );
      const isShoppingCart = url.includes("shopping_cart.jsp");
      const isCollectionPage = url.includes("collections.jsp");
      const iPDPPage = url.includes("product.jsp");
      const isPGPage = url.includes("products.jsp");
      const isRelatedProduct = referrer.includes("product.jsp") && iPDPPage;
      const cauroselCollection = memoryStorage.getItem("cauroselCollection");
      const previousPageName = pageName?.split(".jsp")?.[0]?.replace("_", " ");
      let previousPage =
        previousPageName === "Collections"
          ? "CG"
          : previousPageName === "Products"
          ? "PG"
          : previousPageName === "Product"
          ? "PDP"
          : startCase(previousPageName);

      if (isLanguagePage) {
        previousPage = "Home";
      }
      let item_list_name = "";
      if (page?.referrer === url) {
        item_list_name = `External -> ${isPGPage ? "PG" : "CG"}`;
      } else if (isRelatedProduct) {
        item_list_name = `PDP -> Related PDP`;
      } else {
        if (isShoppingCart) {
          item_list_name = `${
            previousPage || (isLanguagePage ? "Home" : "PG")
          } -> Shopping Cart`;
        } else if (isPGPage) {
          item_list_name = `${
            previousPage || (previousURL ? "Nav" : "PG")
          } -> PG`;
        } else if (iPDPPage) {
          item_list_name = `${previousPage} -> PDP`;
        } else if (cauroselCollection) {
          item_list_name = `CauroselCollection -> CG`;
        } else {
          item_list_name = `Nav -> ${isCollectionPage ? "CG" : "PG"}`;
        }
      }
      const primaryProduct = itemList?.map(item => {
        const itemPriceKey = hasMemberPrice ? "memberPrices" : "listPrices";
        const { priceData } = getPriceData(
          rhUser?.userType,
          item?.price,
          itemPriceKey,
          item?.inStockProductCard
        );

        if (rhUser?.userType === "TRADE") {
          price = (item?.price?.tradePrice || item?.price?.itemPrice) / 100;
        } else if (rhUser?.userType === "CONTRACT") {
          price = (item?.price?.contractPrice || item?.price?.itemPrice) / 100;
        } else {
          const salePrice = item?.price?.salePrice;
          if (salePrice !== null && salePrice < item?.price?.[priceKey]) {
            price = salePrice / 100;
          } else {
            price = item?.price?.[priceKey] / 100;
          }
        }
        memoryStorage.removeItem("fromNav");
        let item_id;
        if (
          !item.productId &&
          !item.id &&
          item?.displayName === "RH Members Program"
        ) {
          item_id = item?.displayName;
          item_list_name = "Shopping Cart -> Shopping Cart";
        } else {
          item_id = item.productId || item.id;
        }
        const writeData = (data: object) => {
          var newDiv = document.createElement("div");
          newDiv.id = "outputSpan2";
          newDiv.innerHTML = `${JSON.stringify(data)}`;
          document.body.appendChild(newDiv);
        };
        const relatedProductSkuId = relatedProductSku?.includes(
          item?.sku?.toString()
        );
        const productConfig =
          item?.productConfig ||
          preConfigureProduct({
            fullSkuId: item?.sku,
            preConfiguredSku: fullSkuIdFromParam,
            relatedProductSkuId
          });
        !processEnvServer && memoryStorage.removeItem("panelLineItem");
        return {
          item_id: item_id,
          item_name:
            item?.product?.displayName || item?.displayName || item?.name,
          item_variant: item?.sku ?? null,
          quantity: item?.quantity,
          price:
            item?.pricing ||
            (!item?.price ? null : price || priceData?.price?.[0]),
          item_category:
            item?.category || item?.product?.category || isShoppingCart
              ? null
              : title || productData?.category,
          item_list_name: detail.data.local?.item_list_name
            ? item_list_name
            : null,
          primaryProduct: !productId
            ? "-"
            : productId === item?.productId || productId === item?.id,
          ...(isPreconfigureSkuEvents ? { productConfig } : {}),
          ...(item?.atc_method ? { atc_method: item?.atc_method } : {})
        };
      });
      return [
        ...(!panelLineItem?.addedFromPanel ? primaryProduct : []),
        ...(panelLineItem?.items
          ? panelLineItem?.items?.map(lineItem => {
              const price = lineItem?.pricing || lineItem?.price;
              const priceKey = lineItem?.price
                ? hasMemberPrice
                  ? "memberPrices"
                  : "listPrices"
                : normalPriceKey;
              const { priceData } = getPriceData(
                rhUser?.userType,
                price,
                priceKey,
                lineItem?.inStockProductCard
              );
              const relatedProductSkuId = relatedProductSku?.includes(
                lineItem?.sku?.toString()
              );
              const productConfig =
                lineItem?.productConfig ||
                preConfigureProduct({
                  fullSkuId: lineItem?.sku,
                  preConfiguredSku: fullSkuIdFromParam,
                  relatedProductSkuId
                });
              !processEnvServer && memoryStorage.removeItem("panelLineItem");
              return {
                item_id: lineItem?.id,
                item_name: lineItem?.name,
                item_variant: lineItem?.sku,
                quantity: lineItem?.quantity,
                price: priceData?.price,
                item_category: title || productData?.category,
                item_list_name: detail.data.local?.item_list_name,
                primaryProduct: !productId
                  ? "-"
                  : productId === lineItem?.productId ||
                    productId === lineItem?.id,
                ...(isPreconfigureSkuEvents ? { productConfig } : {}),
                ...(lineItem?.atc_method
                  ? { atc_method: lineItem?.atc_method }
                  : {})
              };
            })
          : [])
      ];
    }
    const { name, quantity, id, sku, pricing, currencyCode } =
      detail.data.local.item;
    const inStockProductCard = detail.data.local.item.inStockProductCard;
    const location = detail.data.location!;
    const { stockOrSale, priceData } = getPriceData(
      rhUser?.userType,
      pricing,
      normalPriceKey,
      inStockProductCard
    );
    const primaryProduct = [
      {
        name,
        brand: getBrand(location as InternalLocation).brand,
        id,
        quantity,
        channel: fieldBuilder.channel(detail),
        unit_price: priceData?.price,
        price: priceData?.type,
        currency: currencyCode,
        sku,
        stockOrSale,
        productDocId,
        primaryProduct: !productId ? "-" : productId === id
      }
    ];
    !processEnvServer && memoryStorage.removeItem("panelLineItem");
    return [
      ...(!panelLineItem?.addedFromPanel ? primaryProduct : []),
      ...panelLineItem?.items?.map(item => {
        const { stockOrSale, priceData } = getPriceData(
          rhUser?.userType,
          item?.pricing,
          normalPriceKey,
          item?.inStockProductCard
        );
        return {
          name: item?.name,
          brand: getBrand(location as InternalLocation).brand,
          id: item?.id,
          quantity: item?.quantity,
          channel: fieldBuilder.channel(detail),
          unit_price: priceData?.price,
          price: priceData?.type,
          currency: currencyCode,
          sku: item?.sku,
          stockOrSale,
          productDocId,
          primaryProduct: !productId
            ? "-"
            : productId === item?.productId || productId === item?.id
        };
      })
    ];
  },

  cart: (detail: AnalyticsEventDetail): Array<any> | null => {
    const page = fieldBuilder.page(detail);
    if (
      page.type === "checkout" ||
      page.type === "purchase" ||
      page.type === "payment-thankyou"
    ) {
      const cartItems = memoryStorage.getItem("analytics-cartItems") || "";
      let analyticsCart: Record<string, unknown>[] = [];
      if (cartItems !== "" && cartItems?.length > 0) {
        cartItems?.forEach(item => {
          analyticsCart.push({
            id: item?.productId,
            name: item?.displayName,
            brand: resources.brands[item?.brand].brand,
            quantity: item?.quantity,
            sku: item?.sku,
            unit_price: item?.price?.itemPrice
              ? item?.price?.itemPrice / 100
              : item?.price?.itemPrice,
            pricing: item?.price?.priceStatus,
            currency: item?.price?.currencyCode,
            fusionId: item?.fusionId?.split("~")[0] || null
          });
        });
      }
      return analyticsCart;
    } else {
      return null;
    }
  },
  cartdetails: (detail: AnalyticsEventDetail): Array<any> | null => {
    const page = fieldBuilder.page(detail);
    if (
      page.type === "checkout" ||
      page.type === "purchase" ||
      page.type == "payment"
    ) {
      return memoryStorage.getItem("analytics-cartDetails") || null;
    } else {
      return detail?.data?.local?.cartdetails || null;
    }
  },
  transaction: (detail: AnalyticsEventDetail): Object | null => {
    const page = fieldBuilder.page(detail);
    const transactionData =
      detail.data.local?.transactionData ||
      memoryStorage.getItem("analytics-orderDetails");
    if (
      page.type === "purchase" ||
      page.type === "payment" ||
      transactionData
    ) {
      const order = memoryStorage.getItem("analytics-orderDetails") || {};
      if (Object.keys(order).length === 0 && page.type === "payment-thankyou") {
        return null;
      }
      const rhUser = memoryStorage.getItem("analytics-userInfo") || "";
      const UFDCost = order?.cartPrice?.freight?.unlimited;
      const surchargeCost = order?.cartPrice?.freight?.shippingSurcharge;
      let shippingCost =
        order?.cartPrice?.freight?.ground ||
        order?.cartPrice?.freight?.next ||
        order?.cartPrice?.freight?.second;
      shippingCost = shippingCost
        ? UFDCost
          ? UFDCost + shippingCost
          : shippingCost
        : UFDCost;
      shippingCost = shippingCost
        ? surchargeCost
          ? surchargeCost + shippingCost
          : shippingCost
        : surchargeCost;
      const orderDetails = {
        order: {
          transaction_id: order?.orderNumber,
          subtotal: order?.cartPrice?.subtotal / 100,
          shipping: shippingCost ? shippingCost / 100 : null,
          tax: order?.cartPrice?.tax / 100,
          total: order?.payments?.totalAmountCovered / 100,
          discount: order?.cartPrice?.discounts
            ? order?.cartPrice?.discounts?.discountCodeTotal
              ? order?.cartPrice?.discounts?.discountCodeTotal / 100
              : null
            : null,
          currency: order?.cartPrice?.currencyCode
        },
        customer: {
          id: rhUser?.id || order?.id,
          shipping_city: order?.shipAddress?.city,
          shipping_zip: order?.shipAddress?.postalCode,
          shipping_state: order?.shipAddress?.state,
          billing_zip: order?.billAddress?.postalCode
        }
      };
      memoryStorage.setItem("analytics-orderData", orderDetails);
      if (
        (page?.type === "purchase" &&
          detail?.type === "tracking-int-view-page") ||
        page?.type === "payment-thankyou"
      ) {
        memoryStorage.removeItem("analytics-orderDetails");
      }
      return orderDetails;
    } else {
      return null;
    }
  },
  gallery: (detail: AnalyticsEventDetail): string | undefined =>
    detail.data.local.gallery,
  tier1: (detail: AnalyticsEventDetail): string | undefined => {
    const { target, delegated, closestAnchor: closest } = detail.data.local;
    const item =
      detail.data.local["cta_click"]?.detail?.item || detail.data.local.item;
    let catalogArray = getNavigationDetails();
    let tier1Banner = "";
    const banner = detail.data.local["cta_click"]?.detail?.closestAnchor;
    const closestAnchor = closest || banner;
    if (item?.isCountrySwitch || item?.popUpSwitch || item?.fromHeader) {
      tier1Banner = "country switcher click";
    } else if (item?.isPaginationClick) {
      tier1Banner = "pagination click";
    } else if (!target && !delegated && !closestAnchor) {
      if (item?.isGuestHouseNavigation || item?.isRequestConsultation) {
        tier1Banner = "Banner (Static)";
      } else if (item?.isHamburgerMenuOpened) {
        tier1Banner = item?.navigationName;
      } else if (item?.navigationElement?.["data-gtm-id"]?.includes("footer")) {
        tier1Banner = "Footer";
      } else if (
        item?.navigationElement?.["data-gtm-id"]?.includes("uber") ||
        item?.navigationElement?.displayName === "SALE"
      ) {
        tier1Banner = "Navigation/Uber-Nav";
      } else if (item?.isMakeOrConfirmReservation) {
        tier1Banner = "Restaurant Reservation Click";
      } else {
        tier1Banner = "Navigation/Sub-Nav";
      }
    } else {
      if (
        delegated?.includes("footer") &&
        !(closestAnchor?.getAttribute("data-worh-type") === "worh-footer")
      ) {
        tier1Banner = "Footer";
      } else if (
        closestAnchor?.getAttribute("data-worh-type") === "worh-icon"
      ) {
        tier1Banner = "Navigation/HamburgerMenu";
      } else if (
        closestAnchor?.getAttribute("data-analytics-worhlogo") === "worh-logo"
      ) {
        tier1Banner = "Navigation/HOMEPAGE-LOGO";
      } else if (delegated?.includes("rhr-sub-cat")) {
        tier1Banner = "Navigation/Sub-Nav";
      } else if (
        [
          "nav",
          "Toolbar",
          "sign-in",
          "my-account",
          "brand-links-container",
          "rhr-uber-cat"
        ].some(v => delegated?.includes(v))
      ) {
        tier1Banner = "Navigation/Uber-Nav";
      } else if (item?.isDrawerNavigation) {
        tier1Banner =
          catalogArray?.length > 1
            ? "Navigation/Sub-Nav"
            : "Navigation/Uber-Nav";
      } else if (item?.isHeaderNavigation) {
        tier1Banner = "Navigation/Sub-Nav";
      } else if (closestAnchor?.getAttribute("id") === "nav-logo-img") {
        tier1Banner = "Navigation/Uber-Nav";
      } else if (delegated?.includes("rhr-sub-nav")) {
        tier1Banner = "Navigation/Sub-Nav";
      } else {
        const bannertype =
          closestAnchor?.getAttribute("bannertype") ||
          closestAnchor?.getAttribute("data-bannertype");
        tier1Banner =
          bannertype ||
          target.closest("[bannertype]")?.getAttribute("bannertype");
      }
    }
    return tier1Banner || "";
  },
  tier2: (detail: AnalyticsEventDetail): string | undefined => {
    const { target, delegated, closestAnchor: closest } = detail.data.local;
    const item =
      detail.data.local["cta_click"]?.detail?.item || detail.data.local.item;
    const banner = detail.data.local["cta_click"]?.detail?.closestAnchor;
    const closestAnchor = closest || banner;
    let catalogArray = getNavigationDetails();
    let tier2Banner = "";
    if (item?.fromHeader) {
      tier2Banner = "Switcher Header click";
    } else if (item?.isCountrySwitch) {
      tier2Banner = item?.fromFooter
        ? "footer click"
        : "postal code switcher click";
    } else if (item?.popUpSwitch) {
      tier2Banner = "Switcher Pop up click";
    } else if (item?.isPaginationClick) {
      tier2Banner = item?.pageType;
    } else if (item?.isGuestHouseNavigation || item?.isRequestConsultation) {
      tier2Banner = "position01";
    } else if (delegated?.includes("footer")) {
      const closestLineTag = delegated?.includes("worh-nav-footer")
        ? target?.closest("li")
        : null;
      tier2Banner = closestLineTag
        ? closestLineTag?.getAttribute("data-analytics-parentCat")
        : closestAnchor?.getAttribute("data-analytics-parentid") || "Footer";
    } else if (closestAnchor?.getAttribute("data-worh-type") === "worh-icon") {
      tier2Banner = closestAnchor?.innerText
        ? closestAnchor?.getAttribute("data-analytics-rootcat") ||
          closestAnchor?.innerText
            ?.replace(/\r?\n|\r/g, " ")
            .replace(/ +/g, " ")
        : closestAnchor?.pathname?.includes("content")
        ? "OUR " + closestAnchor?.pathname?.split("/")[5]?.toUpperCase()
        : "OUR " + closestAnchor?.pathname?.split("/")[1]?.toUpperCase();
    } else if (
      closestAnchor?.getAttribute("data-analytics-worhlogo") === "worh-logo"
    ) {
      tier2Banner = "HOMEPAGE-LOGO";
    } else if (
      [
        "nav",
        "Toolbar",
        "sign-in",
        "my-account",
        "brand-links-container",
        "rhr-uber-cat"
      ].some(v => delegated?.includes(v))
    ) {
      tier2Banner = "Uber";
    } else if (
      (catalogArray?.length === 1 && item?.isDrawerNavigation) ||
      item?.navigationElement?.displayName === "SALE"
    ) {
      tier2Banner = "Uber";
    } else if (item?.isHeaderNavigation) {
      tier2Banner = item?.topCat;
    } else if (item?.isDrawerNavigation) {
      let catalogArray = getNavigationDetails();
      tier2Banner =
        catalogArray?.length !== 0
          ? catalogArray[0]?.name || catalogArray[0]?.displayName
          : item?.navigationElement?.displayName;
    } else if (item?.isHamburgerMenuOpened) {
      tier2Banner = item?.iconName;
    } else if (delegated?.includes("rhr-sub-cat")) {
      tier2Banner = closestAnchor?.getAttribute("data-analytics-rootcat");
    } else if (item?.isMakeOrConfirmReservation) {
      tier2Banner = item?.restaurantName;
    } else {
      const positioninfo =
        closestAnchor?.getAttribute("positioninfo") ||
        closestAnchor?.getAttribute("data-positioninfo");
      tier2Banner =
        positioninfo ||
        target?.closest("[positioninfo]")?.getAttribute("positioninfo");
      let slidePosition = "";
      slidePosition =
        closestAnchor?.getAttribute("slideposition") ||
        target?.closest("[slideposition]")?.getAttribute("slideposition") ||
        "";
      if (slidePosition && slidePosition !== "") {
        tier2Banner = `${tier2Banner}/${slidePosition}`;
      }
    }
    return tier2Banner || "";
  },
  tier3: (detail: AnalyticsEventDetail): string | undefined => {
    const { target, delegated, closestAnchor: closest } = detail.data.local;
    const item =
      detail.data.local["cta_click"]?.detail?.item || detail.data.local.item;
    const banner = detail.data.local["cta_click"]?.detail?.closestAnchor;
    const closestAnchor = closest || banner;
    const targetURL = closestAnchor?.getAttribute("data-analytics-targeturl");
    const { innerText } = target || {};
    let tier3Banner = "";
    if (item?.isCountrySwitch || item?.popUpSwitch || item?.fromHeader) {
      tier3Banner = item.country;
    } else if (item?.isPaginationClick) {
      tier3Banner = item?.pageNo;
    } else if (item?.isRequestConsultation) {
      tier3Banner = "Submit";
    } else if (item?.isGuestHouseNavigation) {
      tier3Banner = item?.cityname;
    } else if (delegated?.includes("footer")) {
      tier3Banner = innerText?.replace(/\n/g, " ");
    } else if (closestAnchor?.getAttribute("data-worh-type") === "worh-icon") {
      if (closestAnchor?.getAttribute("data-analytics-parentcat")) {
        tier3Banner = closestAnchor?.getAttribute("data-analytics-parentcat");
      } else if (closestAnchor?.innerText?.includes("OUR")) {
        tier3Banner = closestAnchor?.innerText
          ?.replace(/\r?\n|\r/g, " ")
          .replace(/ +/g, " ");
      } else if (
        [
          "RH",
          "RH MODERN",
          "RH OUTDOOR",
          "RH MODERN",
          "RH INTERIORS",
          "RH CONTEMPORARY",
          "RH BEACH HOUSE",
          "RH SKI HOUSE",
          "RH BABY & CHILD",
          "RH TEEN",
          "RH MEMBERS PROGRAM"
        ].some(brand => brand === closestAnchor?.innerText)
      ) {
        tier3Banner =
          closestAnchor?.innerText?.split(" ").length > 1
            ? closestAnchor?.innerText?.split(" ").slice(1).join(" ")
            : "RH";
      } else {
        tier3Banner = closestAnchor?.innerText
          ? closestAnchor?.innerText
          : "Image";
      }
    } else if (
      closestAnchor?.getAttribute("data-analytics-worhlogo") === "worh-logo"
    ) {
      tier3Banner = "WORH HOME";
    } else if (delegated?.includes("nav")) {
      if (delegated?.includes("img")) {
        tier3Banner = "RH LOGO";
      } else if (delegated?.includes("button")) {
        tier3Banner = "SEARCH ICON";
      } else if (closestAnchor?.getAttribute("href")?.includes("cart")) {
        tier3Banner = "SHOPPING CART";
      } else if (delegated?.includes("account-icon")) {
        tier3Banner = "SIGN IN";
      } else {
        tier3Banner = delegated?.includes("uber-nav")
          ? target?.innerText
          : target?.closest("a")?.innerText;
      }
    } else if (delegated?.includes("my-account")) {
      tier3Banner = "MY ACCOUNT MENU";
    } else if (delegated?.includes("Toolbar")) {
      if (closestAnchor?.getAttribute("id") === "nav-logo-img") {
        tier3Banner = "RH LOGO";
      } else if (closestAnchor?.getAttribute("href")?.includes("cart")) {
        tier3Banner = "SHOPPING CART";
      } else if (
        delegated?.includes("search") ||
        delegated?.includes("button") ||
        delegated?.includes("input")
      ) {
        tier3Banner = "SEARCH ICON";
      } else if (
        closestAnchor?.innerText === "SALE" ||
        closestAnchor?.innerText === "Registry"
      ) {
        tier3Banner = innerText;
      } else if (
        closestAnchor?.getAttribute("data-analytics-nav") === "account-icon"
      ) {
        tier3Banner = "SIGN IN";
      }
    } else if (item?.isHeaderNavigation) {
      tier3Banner = item?.parentCat;
    } else if (item?.isDrawerNavigation) {
      let catalogArray = getNavigationDetails();
      tier3Banner =
        catalogArray?.length !== 0
          ? catalogArray[1]?.name || catalogArray[0]?.name
          : item?.navigationElement?.displayName;
    } else if (targetURL?.includes("/interior-design")) {
      tier3Banner = target?.innerHTML || innerText;
    } else if (
      delegated?.includes("brand-links-container") ||
      delegated?.includes("rhr-uber-cat")
    ) {
      tier3Banner =
        innerText?.split(" ").length > 1
          ? innerText?.split(" ").slice(1).join(" ")
          : "RH";
    } else if (closestAnchor?.innerText === "SALE") {
      tier3Banner = "SALE";
    } else if (item?.isHamburgerMenuOpened) {
      tier3Banner = item?.iconName;
    } else if (delegated?.includes("rhr-sub-cat")) {
      tier3Banner = closestAnchor?.getAttribute("data-analytics-parentcat");
    } else if (item?.isMakeOrConfirmReservation) {
      tier3Banner = item?.restaurantName;
    } else {
      const bannername =
        closestAnchor?.getAttribute("bannername") ||
        closestAnchor?.getAttribute("data-bannername");
      tier3Banner =
        bannername ||
        target?.closest("[bannername]")?.getAttribute("bannername");
    }
    return tier3Banner || "";
  },
  tier4: (detail: AnalyticsEventDetail): string | undefined => {
    const {
      closestAnchor: closest,
      target,
      delegated,
      swipe
    } = detail.data.local;
    const item =
      detail.data.local["cta_click"]?.detail?.item || detail.data.local?.item;
    const targetURL = closest?.getAttribute("data-analytics-targeturl");
    const banner = detail.data.local["cta_click"]?.detail?.closestAnchor;
    const closestAnchor = closest || banner;
    const href = closestAnchor?.getAttribute("href");
    if (swipe) {
      return swipe;
    } else if (item?.isCountrySwitch || item?.popUpSwitch || item?.fromHeader) {
      return countryFullNameMapper[item?.language || "en"];
    } else if (
      targetURL?.includes("/interior-design") ||
      (item?.isHeaderNavigation && item?.catURL?.includes("/interior-design"))
    ) {
      return item?.catURL || targetURL;
    } else if (item?.isHeaderNavigation) {
      return item?.cat;
    } else if (item?.isDrawerNavigation) {
      let catalogArray = getNavigationDetails();
      if (catalogArray?.length !== 0) {
        return item?.navigationElement?.displayName;
      }
      return item?.navigationElement?.targetUrl;
    } else if (delegated?.includes("my-account")) {
      return target.innerText;
    } else if (delegated?.includes("brand-links-container")) {
      const landingURL = target
        ?.closest("li")
        .getAttribute("data-analytics-url");
      return detail?.data.location?.pathname === "/"
        ? landingURL
        : detail?.data.location?.pathname;
    } else if (delegated?.includes("rhr-uber-cat")) {
      return closestAnchor?.getAttribute("data-analytics-targetURL");
    } else if (closestAnchor?.getAttribute("data-analytics-parentcat")) {
      return closestAnchor?.innerText;
    } else if (delegated?.includes("worh-nav-footer")) {
      const closestLineTag = target?.closest("li");
      return closestLineTag?.getAttribute("data-analytics-targetURL");
    } else if (item?.isGuestHouseNavigation) {
      return item?.pagename;
    } else if (item?.isMakeOrConfirmReservation) {
      return item?.cat;
    } else {
      const ctaname =
        closestAnchor?.getAttribute("ctaname") ||
        closestAnchor?.getAttribute("data-ctaname");
      if (ctaname) {
        return ctaname;
      } else {
        return (
          target?.closest("[ctaname]")?.getAttribute("ctaname") ||
          detail.data.local?.target?.href ||
          href ||
          location.pathname
        );
      }
    }
  },
  tier5: (detail: AnalyticsEventDetail): string | undefined => {
    const { closestAnchor: closest, delegated, target } = detail.data.local;
    const item =
      detail.data.local["cta_click"]?.detail?.item || detail.data.local.item;
    const banner = detail.data.local["cta_click"]?.detail?.closestAnchor;
    const closestAnchor = closest || banner;
    const href = closestAnchor?.getAttribute("href");
    if (item?.isHeaderNavigation) {
      return item?.catURL;
    } else if (item?.isDrawerNavigation) {
      return item?.navigationElement?.targetUrl
        ? item.navigationElement.targetUrl
        : "No URL Present";
    } else if (delegated?.includes("brand-links-container")) {
      const landingURL = target
        ?.closest("li")
        .getAttribute("data-analytics-url");
      return detail.data.location?.pathname === "/"
        ? landingURL
        : detail.data.location?.pathname;
    } else if (delegated?.includes("worh-nav-footer")) {
      const closestLineTag = target?.closest("li");
      return closestLineTag?.getAttribute("data-analytics-targetURL");
    } else if (item?.isGuestHouseNavigation) {
      return item?.targetURL;
    } else {
      const emailSignUp =
        target?.closest("[bannername]")?.getAttribute("bannername") ===
        "Sign up for email";
      if (emailSignUp) {
        return emailSignUp ? detail.data.location?.pathname : "";
      } else {
        return (
          detail.data.local?.target?.href ||
          closestAnchor?.getAttribute("data-analytics-targeturl") ||
          href ||
          location.pathname
        );
      }
    }
  },
  pdpclicktype: (
    detail: AnalyticsEventDetail
  ): GTMPdpclickField | undefined => {
    const page = fieldBuilder.page(detail);
    return {
      category: "PRE PDP Links",
      action: detail.data.local["pre-pdp-click"].detail?.item?.action,
      label: detail.data.local["pre-pdp-click"].detail?.item?.label
        ? detail.data.local["pre-pdp-click"].detail?.item?.label
        : page.title
    };
  },
  cgclicktype: (detail: AnalyticsEventDetail): GTMPdpclickField | undefined => {
    const page = fieldBuilder.page(detail);
    const title =
      !processEnvServer && localStorage.getItem("analytics-category-title");
    return {
      category: "CG Links",
      action: detail.data.local["cg-click"].detail?.item?.action,
      label: title || page.title
    };
  },
  pgclicktype: (detail: AnalyticsEventDetail): GTMPdpclickField | undefined => {
    const page = fieldBuilder.page(detail);
    const title =
      !processEnvServer && localStorage.getItem("analytics-category-title");
    return {
      category: "PG Links",
      action: detail.data.local["pg-click"].detail?.item?.action,
      label: title || page.title
    };
  },
  selectedswatchesobj: (
    detail: AnalyticsEventDetail
  ): GTMSwatchesField | undefined => {
    const swatchesObj = detail.data.local?.selectedSwatchesObj;
    let swatches: {
      swatchGroupName: void;
      swatchName: void;
      skuId: void;
      swatchId: void;
    }[] = [];
    swatchesObj.selectedSwatches.forEach(
      (swatch: {
        swatchColors: { title: void; skuId: void; swatchId: void }[];
        swatchGroupName: void;
      }) => {
        swatch.swatchColors.forEach(swatchColor => {
          swatches.push({
            swatchGroupName: swatch.swatchGroupName,
            swatchName: swatchColor.title,
            skuId: swatchColor.skuId,
            swatchId: swatchColor.swatchId
          });
        });
      }
    );
    return {
      swatches,
      orderId: swatchesObj.orderId
    };
  },
  form_name: (detail: AnalyticsEventDetail): string => {
    const formNameMapper = {
      "rh-email-signup-form-container": "Footer Subscription Form",
      "rh-trade-sales-app-form-container": "RH Trade Application",
      "rh-consultation-form-container": "Request A Design Consultation",
      "rh-create-registry-form-container": "Create a Gift Registry",
      "rh-request-billing-statement-form-container":
        "Request Billing Statement",
      "rh-contact-us-form-container": "Contact Us",
      "rh-cancel-sourcebook-form-container": "Cancel SourceBook",
      "rh-email-signup-container-form": "Email Signup",
      "rh-email-signup-unsubscribe-form-container": "Email Signup Unsubscribe",
      "rh-sourcebook-form-container": "Sourcebook Request Form"
    };

    const formId = detail.data.local?.form.form_id;
    const formName = formNameMapper[formId] || "Form Submit";
    return detail.data.local?.form.form_name || formName;
  },
  form_id: (detail: AnalyticsEventDetail): string =>
    detail.data.local?.form.form_id || "",
  form_type: (detail: AnalyticsEventDetail): string =>
    detail.data.local?.form.form_type || "",
  form_submit_status: (detail: AnalyticsEventDetail): boolean =>
    !detail.data.local?.form.form_start,
  form_start: (detail: AnalyticsEventDetail): boolean =>
    detail.data.local?.form.form_start || false,
  shopbyroom: (
    detail: AnalyticsEventDetail
  ): GTMShopbyroomField | undefined => {
    const page = fieldBuilder.page(detail);
    const room = detail.data.local?.roomTitle;
    const item = detail.data.local?.slideTitle;
    if (page.key === "shopbyroom") {
      if (!processEnvServer) {
        localStorage.removeItem("analytics-catalog");
      }
      return {
        album: detail.data.location?.paramMap?.id,
        slide:
          detail.data.location?.paramMap?.slideId ||
          detail.data.location?.paramMap?.slideIds,
        room: room ? room : "",
        item: item ? item : ""
      };
    }
  },
  tradereq: (detail: AnalyticsEventDetail): GTMTradeRequestField => {
    const typeArr = [
      "Request a Quote",
      "Request a Swatch",
      "Request Product Information",
      "Request Support for a Project",
      "Request an Appointment",
      "Request a Trade Account"
    ];
    const isTypeOther = typeArr.includes(detail.data.local?.trade?.message);
    return {
      preferredMethod: detail.data.local?.trade?.preferredContact,
      typeOfTrade: isTypeOther ? detail.data.local?.trade?.message : "Other"
    };
  },
  chattopic: (detail: AnalyticsEventDetail): string => {
    const { target } = detail.data.local;
    return (
      target?.closest(".prechatUI")?.querySelector("#Select_a_Topic__c")
        ?.value || ""
    );
  },
  chatendreason: (detail: AnalyticsEventDetail): string =>
    detail.data.local["chat-conference-ended"]?.detail.reason || "",
  zeroresult: (detail: AnalyticsEventDetail): GTMZeroResultsField => {
    return {
      searchTerm: detail.data.local?.searchTerm,
      destinationBrand: detail.data.local?.destinationBrand
    };
  },
  returnitems: (detail: AnalyticsEventDetail): GTMOnlineReturnItems => {
    const date = new Date();
    let selectedLines = detail.data.local?.selectedLines;
    return {
      orderNumber: `${detail.data.local?.orderNumber}|${date.getTime()}`,
      shippingMethod: detail.data.local?.returnShippingMethod,
      numberOfBoxes: detail.data.local?.numberOfBoxes,
      returnItems: detail.data.local?.returnItems.map(
        (item: IReturnItem, index: number) => ({
          skuId: item.sku,
          additionalComment: item.description,
          returnReason1: item.returnReason1,
          returnReason2: item.returnReason2,
          returnReason3: item.returnReason3,
          quantity: item.quantity,
          isPhotoUploaded: item.photos.length > 0 ? true : false,
          lineAmount:
            item.quantity *
            (selectedLines[index]?.pricing.actualAmount?.amount || 0),
          itemName: selectedLines[index]?.displayName || ""
        })
      ),
      refundAmount: detail.data.local?.refundAmount
    };
  },
  removedItem: (detail: AnalyticsEventDetail): GTMOnlineRemoveItem => {
    const item = detail.data.local?.itemToReturn;
    return {
      shipToNumber: item && item.shipToNumber,
      number: item && item.number,
      orderNumber: item && item.orderNumber,
      itemName: (item && item.displayName) || "",
      skuId: item && item.fullSkuId
    };
  },
  video: (detail: AnalyticsEventDetail): Object | null => {
    return detail.type.includes("video") ? detail.data.local.video : null;
  },
  actionField: (detail: AnalyticsEventDetail): Object | null => {
    const page = fieldBuilder.page(detail);
    if (page.type === "checkout") {
      const actionData = {
        option:
          detail.type === "tracking-int-eec-checkout"
            ? memoryStorage.getItem("analytics-promo-code") || null
            : memoryStorage.getItem("analytics-paymentType") || null
      };
      memoryStorage.removeItem("analytics-promo-code");
      memoryStorage.removeItem("analytics-paymentType");
      return actionData;
    } else {
      return null;
    }
  },
  subscribedbrands: (detail: AnalyticsEventDetail): Object | null => {
    return detail.data?.local?.brands?.subscribedbrands || null;
  },
  collectioncategory: (detail: AnalyticsEventDetail): Object | null => {
    const page = fieldBuilder.page(detail);
    if (page.key === "collections" || page.key === "products") {
      return detail.data?.local?.collectioncategory || null;
    }
    return null;
  },
  layout: (detail: AnalyticsEventDetail): Object | null => {
    const page = fieldBuilder.page(detail);
    if (
      page?.key === "collections" ||
      page?.key === "products" ||
      page?.key === "search" ||
      page?.key === "product"
    ) {
      return detail.data?.local?.layout || null;
    }
    return null;
  },
  rh_tokens: (): Array<RHToken> | null => {
    const rhTokens = !processEnvServer && window && window["rh_tokens"];
    if (rhTokens && rhTokens?.length) {
      const tokens = rhTokens?.filter(
        (token, index, token1) =>
          token1.findIndex(
            token2 => token2.campaign_id === token.campaign_id
          ) === index
      );
      return tokens;
    }
    return null;
  },
  searchRule: (detail: AnalyticsEventDetail): string | null => {
    const searchRule = memoryStorage.getItem("ruleExecuted");
    const ruleExecuted = detail.data.local.rule || searchRule;
    if (ruleExecuted) {
      return ruleExecuted;
    }
    return null;
  },
  analytics_storage: (detail: AnalyticsEventDetail): string => {
    return isGDPRCompliantORCookieAccepted() ? "granted" : "denied";
  },
  page_location: (): string => {
    const pageData = memoryStorage.getItem("ga4_pagedata");
    return pageData?.location;
  },
  page_path: (detail: AnalyticsEventDetail): string => {
    const pageData = memoryStorage.getItem("ga4_pagedata");
    const fromNav = detail.data.location?.paramMap?.fromNav;
    const navPath: any =
      !processEnvServer && localStorage.getItem("analytics-navCatalog");
    const navigationData = navPath && JSON.parse(navPath);
    let path;
    if (pageData?.path === "/") {
      return "/Home/Home";
    }
    if (pageData?.path.includes("collections")) {
      const title =
        !processEnvServer && localStorage.getItem("analytics-category-title");
      const collectionId = detail.data.local?.id;
      return `/Collections/${title}/${collectionId}`;
    }
    if (pageData?.path.includes("products")) {
      const title =
        !processEnvServer && localStorage.getItem("analytics-category-title");
      const productsId = detail.data.local?.id;
      return `/Products/${title}/${productsId}`;
    }
    if (pageData?.path.includes("product")) {
      const productData =
        !processEnvServer && localStorage.getItem("analytics-product-data");
      const productTitle = productData && JSON.parse(productData)?.displayName;
      const productId = productData && JSON.parse(productData)?.id;
      return `/Product/${productTitle}/${productId}`;
    }
    if (pageData?.path.includes("search")) {
      const title = detail.data.location?.paramMap?.Ntt;
      const id = navigationData?.cat?.id;
      if (!fromNav) {
        const searches =
          detail.data.history?.previous.filter(p => p.href.includes("/search/"))
            .length || 0;
        return `/Search/Results/${searches}/${title}`;
      } else {
        return `/Results/${title}/${id}`;
      }
    }
    if (pageData?.path.includes("rooms")) {
      const shopbyroom = fieldBuilder.shopbyroom(detail);
      return `/Shop By Room/${shopbyroom?.room}/${shopbyroom?.item}/${shopbyroom?.album}:${shopbyroom?.slide}`;
    }
    if (pageData?.pathData?.section && pageData?.pathData?.name) {
      path = `/${pageData?.pathData?.section}/${pageData?.pathData?.name}`;
      if (pageData?.pathData?.subname) {
        path += `/${pageData?.pathData?.subname}`;
      }
      return path;
    }
    return pageData?.path;
  },
  page_title: (): string => {
    const pageData = memoryStorage.getItem("ga4_pagedata");
    return pageData?.title;
  },
  page_type: (): string => {
    const pageData = memoryStorage.getItem("ga4_pagedata");
    return pageData?.type;
  },
  login_status: (detail: AnalyticsEventDetail): string => {
    const pageData = memoryStorage.getItem("ga4_pagedata");
    return detail.data.local?.loggedin || pageData?.login_status;
  },
  page_error: (): string => {
    const pageData = memoryStorage.getItem("ga4_pagedata");
    return pageData?.error;
  },
  page_referrer: (): string => {
    const pageData = memoryStorage.getItem("ga4_pagedata");
    return pageData?.referrer;
  },
  membership_id: (): string => {
    const pageData = memoryStorage.getItem("ga4_pagedata");
    return pageData?.membership_id || "-";
  },
  click_url: (detail: AnalyticsEventDetail): string => {
    const item = detail.data.local["cta_click"]?.detail?.item;
    const tier5 = fieldBuilder?.tier5(detail);
    return tier5 || item?.catURL || location.href;
  },
  click_text: (detail: AnalyticsEventDetail): string => {
    const tier3 = fieldBuilder?.tier3(detail);
    const tier4 = fieldBuilder?.tier4(detail);
    if (tier4?.includes(tier4?.match(/\/..\/en|https/gi)[0])) {
      return tier3;
    }
    return tier4 || tier3;
  },
  click_class: (detail: AnalyticsEventDetail): string => {
    const { target } = detail.data.local;
    const item = detail.data.local["cta_click"]?.detail?.item;
    return item?.class || target?.getAttribute("class");
  },
  cta_category: (detail: AnalyticsEventDetail): string => {
    return fieldBuilder?.tier1(detail);
  },
  "data-testid": (detail: AnalyticsEventDetail): string => {
    const { target } = detail.data.local;
    const item = detail.data.local["cta_click"]?.detail?.item;
    return (item || target || target?.parentNode)?.id;
  },
  is_member: (): boolean => {
    const pageData = memoryStorage.getItem("ga4_pagedata");
    return pageData?.membership_id ? true : false;
  },
  rh_store: (): string => {
    const country = getCountryFromUrl();
    return country;
  },
  order_date: (): string => {
    return moment().format("DD/MM/YYYY");
  },
  store_number: (detail: AnalyticsEventDetail): string => {
    return detail.data.local?.store_number;
  },
  payment_type: (detail: AnalyticsEventDetail): string => {
    const transactionData =
      detail.data.local?.transactionData ||
      memoryStorage.getItem("analytics-orderDetails");
    return transactionData?.payments?.appliedPayments[0]?.paymentType;
  },
  coupon: (detail: AnalyticsEventDetail): string => {
    const transactionData =
      detail.data.local?.transactionData ||
      memoryStorage.getItem("analytics-orderDetails");
    return (
      detail?.data?.local?.coupon ?? transactionData?.discountCodes?.[0]?.code
    );
  },
  currency: (): string => {
    const urlCountry = getCountryFromUrl();
    let currencyCode =
      countryCurrencyMapper[urlCountry] === "USA"
        ? "USD"
        : countryCurrencyMapper[urlCountry];
    return currencyCode;
  },
  value: (detail: AnalyticsEventDetail): number => {
    const transactionData =
      detail.data.local?.transactionData ||
      memoryStorage.getItem("analytics-orderDetails");
    return (
      (transactionData && calculateSubtotalAmount(transactionData) / 100) || 0
    );
  },
  tax: (detail: AnalyticsEventDetail): number => {
    const transactionData =
      detail.data.local?.transactionData ||
      memoryStorage.getItem("analytics-orderDetails");
    const orderData = memoryStorage.getItem("analytics-orderData") || {};
    return transactionData?.cartPrice?.tax / 100 || orderData?.order?.tax || 0;
  },
  shipping: (detail: AnalyticsEventDetail): number => {
    const transactionData =
      detail.data.local?.transactionData ||
      memoryStorage.getItem("analytics-orderDetails");
    const orderData = memoryStorage.getItem("analytics-orderData") || {};
    const UFDCost = transactionData?.cartPrice?.freight?.unlimited;
    const surchargeCost =
      transactionData?.cartPrice?.freight?.shippingSurcharge;
    let shippingCost =
      transactionData?.cartPrice?.freight?.ground ||
      transactionData?.cartPrice?.freight?.next ||
      transactionData?.cartPrice?.freight?.second;
    shippingCost = shippingCost
      ? UFDCost
        ? UFDCost + shippingCost
        : shippingCost
      : UFDCost;
    shippingCost = shippingCost
      ? surchargeCost
        ? surchargeCost + shippingCost
        : shippingCost
      : surchargeCost;
    return (
      (shippingCost ? shippingCost / 100 : null) || orderData?.order?.shipping
    );
  },
  transaction_id: (detail: AnalyticsEventDetail): string => {
    const orderData = memoryStorage.getItem("analytics-orderData") || {};
    return (
      orderData?.order?.transaction_id ??
      detail?.data?.location?.paramMap?.orderNumber
    );
  },
  customer: (): Object | null => {
    const orderData = memoryStorage.getItem("analytics-orderData") || {};
    return orderData?.customer;
  },
  eventlabel: (detail: AnalyticsEventDetail): string => {
    const item = detail?.data?.local;
    let eventLabel = `${item?.productAddonTitle} - ${item?.productId}`;
    if (item?.fullskuid) {
      eventLabel += ` - ${item?.fullskuid}`;
    }
    return eventLabel;
  },
  eventAction: (): string => {
    return "Add-on Checkbox Click";
  },
  brand: (): string => {
    const pageData = memoryStorage.getItem("ga4_pagedata");
    return pageData?.brand;
  },
  checkout_step: (): string => {
    return "2";
  },
  direction: (detail: AnalyticsEventDetail): string => {
    return detail.data.local?.direction;
  },
  search_term: (detail: AnalyticsEventDetail): string => {
    return detail.data.location?.paramMap?.Ntt;
  },
  search_results_number: (detail): number => {
    return detail.data.local?.search_results_number;
  },
  search_group: (): string => {
    return "not available";
  },
  search_term_group: (detail: AnalyticsEventDetail): string => {
    const pageData = memoryStorage.getItem("ga4_pagedata");
    return detail?.data?.local?.anchor ?? pageData?.title;
  },
  user_id: (detail: AnalyticsEventDetail): string => {
    return detail.data.local?.userId;
  },
  login_method: (): string => {
    return "Email";
  },
  signup_status: (): string => {
    return "Success";
  },
  signup_method: (): string => {
    return "Email";
  },
  video_progress: (detail: AnalyticsEventDetail): string => {
    return detail?.data?.local?.percentage;
  },
  video_title: (detail: AnalyticsEventDetail): string => {
    return detail?.data?.local?.title;
  },
  facet_name: (detail: AnalyticsEventDetail): string => {
    return detail?.data?.local?.facet_name;
  },
  facet_value: (detail: AnalyticsEventDetail): string => {
    return detail?.data?.local?.facet_value;
  },
  method: (): string => {
    return "Manual";
  }
};

export const buildField = (o: AnalyticsEventDetail) => {
  return async (f: string): Promise<any> => {
    let val;
    try {
      val = await fieldBuilder[f](o);
    } catch (error) {}
    return val !== undefined && val !== null && val !== "" ? { [f]: val } : {};
  };
};

export const buildPayload = async (
  detail: AnalyticsEventDetail
): Promise<AnalyticsEventDetail> => {
  return {
    ...detail,
    payload: Object.assign(
      {},
      ...(await Promise.all(detail.spec.fields.map(buildField(detail))))
    )
  };
};
